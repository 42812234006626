<template>
  <div class="kmCenter">
    <div class="handBox">
      <el-button type="primary" @click="goBack" style="width: 92px"
        >返回</el-button
      >
      <span class="pageTopic">店内成长套系</span>
    </div>
    <div class="searchBar">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-width="80px"
      >
        <el-form-item label="宝贝姓名" label-width="80px">
          <el-input
            v-model="formInline.name"
            placeholder="请输入"
            @clear="search"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model="formInline.phone"
            placeholder="请输入"
            @clear="search"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="取件时间" label-width="80px">
          <el-date-picker
            v-model="formInline.pickUpDate"
            type="date"
            placeholder="选择日期"
            format="yyyy - MM - dd"
            value-format="yyyy-MM-dd"
            @change="clearPickDate"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="主套系">
          <el-select
            v-model="formInline.mainPlanDone"
            placeholder="请选择"
            clearable
            @clear="search"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="附加套系">
          <el-select
            v-model="formInline.subPlanDone"
            placeholder="请选择"
            clearable
            @clear="search"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="关键字">
          <el-input
            v-model="formInline.keyWord"
            @input="search"
            placeholder="姓名/手机号/备注 模糊搜索"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="search"
            :loading="tableLoading"
            style="width: 92px; margin-left: 20px"
            >查询</el-button
          >
          <el-button
            type="success"
            @click="openAdd"
            style="width: 92px; margin-left: 20px"
            >导入</el-button
          >
          <el-button
            type="warning"
            @click="exportExcel"
            style="width: 102px; margin-left: 20px"
            >导出Excel</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="mainList">
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 10px"
        stripe
        border
        size="medium"
      >
        <el-table-column align="center" label="序号" width="60">
          <template slot-scope="scope">
            <div>{{ scope.$index + (pages.page - 1) * pages.pageNum + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="parentName"
          label="家长姓名"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="宝宝姓名"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="手机号"
          width="180"
        ></el-table-column>
        <el-table-column align="center" prop="status" label="套系情况" width="180">
          <template slot-scope="scope">
            <div>
              <div
                :class="[
                  { 'redFont': scope.row.mainPlanDone == 0 },
                  { 'blueFont': scope.row.mainPlanDone == '' },
                  { 'greenFont': scope.row.mainPlanDone == 1 },
                ]"
              >
                主套系
                <i class="el-icon-success" v-if="scope.row.mainPlanDone == 1"></i>
                {{
                  scope.row.mainPlanDone == 0 
                    ? "未完成"
                    : scope.row.mainPlanDone == ''|| scope.row.mainPlanDone === undefined
                    ? "-"
                    : scope.row.mainPlanDone == 1
                    ? "已完成"
                    : ""
                }}
              </div>
              <div
                :class="[
                  { 'redFont': scope.row.subPlanDone == 0 },
                  { 'blueFont': scope.row.subPlanDone == '' },
                  { 'greenFont': scope.row.subPlanDone == 1 },
                ]"
              >
                附加套系
                <i class="el-icon-success" v-if="scope.row.subPlanDone == 1"></i>
                {{
                  scope.row.subPlanDone == 0 
                    ? "未完成"
                    : scope.row.subPlanDone == ''|| scope.row.subPlanDone === undefined
                    ? "-"
                    : scope.row.subPlanDone == 1
                    ? "已完成"
                    : ""
                }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderDate"
          label="订单日期"
          width="130"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="pickUpDate"
          label="取件日期"
          width="130"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="orderPrice"
          label="套系金额"
          width="180"
        ></el-table-column>
        <el-table-column align="center" min-width="200" prop="comment" label="备注"></el-table-column>
        <el-table-column align="center" width="200" label="进度">
          <template slot-scope="scope">
            <div>
              <el-steps :active="(scope.row.progress?+scope.row.progress.length:0)" process-status="process" finish-status="finish" direction="vertical" align-center>
                <el-step v-for="(item,ind) in scope.row.progress" :key="ind" :title="item.title" tyle="height:50px;color:red;" ></el-step>
              </el-steps>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <div @click="editConsumer(scope)">
              <el-button size="small" type="primary">编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,sizes, prev, pager, next"
        :current-page="pages.page * 1"
        :total="pages.total"
        :page-size="pages.pageNum * 1"
        style="margin-top: 20px"
        :page-sizes="[10, 12, 15, 17, 20, 25, 30, 100, 1000, pages.total]"
        @size-change="changePageSize"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
    <!-- 弹窗 1  -->
    <el-dialog
      :title="isEdit ? '修改信息' : '客户导入'"
      :visible.sync="dialogVisible"
      top="6vh"
      width="500px"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="70px"
        label-position="left"
      >
        <el-form-item label="家长姓名">
          <el-input v-model="form.parentName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="订单日期">
          <el-date-picker
            v-model="form.orderDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="取件日期">
          <el-date-picker
            v-model="form.pickUpDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="主套系" >
          <el-switch  v-model="form.mainPlanDone" inactive-text="未完成" active-text="已完成" 
          inactive-value="0" active-value="1"
          active-color="#13ce66"  inactive-color="#95a5a6"></el-switch>
        </el-form-item>
        <el-form-item label="附加套系" >
          <el-switch  v-model="form.subPlanDone" inactive-text="未完成"  active-text="已完成"
            inactive-value="0" active-value="1"
            active-color="#13ce66"  inactive-color="#95a5a6"></el-switch>
        </el-form-item>
        <el-form-item label="套系金额">
          <el-input v-model="form.orderPrice" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.comment"
            type="textarea"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="进度">
          <el-input style="margin-bottom: 5px;" v-for="(item,ind) in form.progress" :key="ind" v-model="item.title" type="input" placeholder="请输入" ></el-input>
          <el-button type="success" icon="el-icon-plus" circle @click="addProgress"></el-button>
          <el-button type="danger" icon="el-icon-delete" circle @click="deleteProgress"></el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="!isEdit"
          type="primary"
          @click="onSubmit"
          :loading="btnLoading"
          >确定新增</el-button
        >
        <el-button :loading="btnLoading" v-else type="primary" @click="onEdit"
          >确定修改</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "InnerGrowUpList",
  components: {},
  data() {
    return {
      //  TODO 环境切换
      formInline: {
        parentName: "",
        name: "",
        phone: "",
        keyWord: "",
        pickUpDate: "",
        mainPlanDone: "",
        subPlanDone: "",
      },
      pages: {
        page: 1,
        pageNum: 12,
        total: 0,
      },
      tableData: [],
      dialogVisible: false,
      options2: [
        {
          value: "",
          label: "全部",
        },
        {
          value: '1',
          label: "已完成",
        },
        {
          value: '0',
          label: "未完成",
        },
      ],
      // 添加导入数据
      form: {
        _id: "",
        parentName: "",
        name: "",
        phone: "",
        orderDate: "",
        orderPrice: "",
        comment: "",
        pickUpDate: "",
        progress: [],
        mainPlanDone: "0",
        subPlanDone: "0",
      },
      isEdit: false, // 判断是否为编辑
      tableLoading: false,
      btnLoading: false,
    };
  },
  created() {},
  mounted() {
    this.formInline.keyWord = this.$route.query.keyword?this.$route.query.keyword:'';
    this.formInline.name = this.$route.query.name?this.$route.query.name:'';
    this.getData();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    clearPickDate() {
      if(this.formInline.pickUpDate===null) {
        this.formInline.pickUpDate = '';
      }
      this.search();
    },
    search() {
      this.pages.page = 1;
      this.getData();
    },
    changePage(val) {
      this.pages.page = val * 1;
      this.getData();
    },
    // 获取数据
    async getData() {
      if(this.formInline.pickUpDate===null) {
        this.formInline.pickUpDate = '';
      }
      this.tableLoading = true;
      let paramStr =
        "page=" +
        this.pages.page +
        "&pageNum=" +
        this.pages.pageNum +
        "&parentName=" +
        this.formInline.parentName +
        "&pickUpDate=" +
        this.formInline.pickUpDate +
        "&name=" +
        this.formInline.name +
        "&phone=" +
        this.formInline.phone +
        "&mainPlanDone=" +
        this.formInline.mainPlanDone +
        "&subPlanDone=" +
        this.formInline.subPlanDone +
        "&keyWord=" +
        this.formInline.keyWord;
      await axios
        .get(this.$baseUrl + "/innerGrowUp?" + paramStr)
        .then((res) => {
          setTimeout(() => {
            this.tableLoading = false;
          }, 200);
          this.tableData = res.data.list;
          this.pages = res.data.pages;
        })
        .catch((err) => {
          this.tableLoading = false;
          console.log(err);
        });
    },
    //导出excel
    exportExcel() {
      if(this.formInline.pickUpDate===null) {
        this.formInline.pickUpDate = '';
      }
      axios
        .get(
          this.$baseUrl +
            "/innerGrowUp/exportExcel?" +
            "page=" +
            this.pages.page +
            "&pageNum=" +
            this.pages.pageNum +
            "&parentName=" +
            this.formInline.parentName +
            "&pickUpDate=" +
            this.formInline.pickUpDate +
            "&name=" +
            this.formInline.name +
            "&phone=" +
            this.formInline.phone +
            "&mainPlanDone=" +
            this.formInline.mainPlanDone +
            "&subPlanDone=" +
            this.formInline.subPlanDone +
            "&keyWord=" +
            this.formInline.keyWord
            )
        .then((res) => {
          console.log("导出");
          window.open(res.data.url);
        });
    },
    // 点击导入
    openAdd() {
      this.form = {
        _id: "",
        parentName: "",
        name: "",
        phone: "",
        orderDate: "",
        orderPrice: "",
        comment: "",
        pickUpDate: "",
        mainPlanDone: "0",
        subPlanDone: "0",
        progress: []
      };
      this.dialogVisible = true;
      this.isEdit = false;
    },
    // 点击编辑，编辑已有用户信息
    editConsumer(scope) {
      this.isEdit = true;
      this.dialogVisible = true;
      let { parentName, name, phone, orderDate, orderPrice, pickUpDate, comment, progress, _id, mainPlanDone, subPlanDone } = scope.row;
      this.form = {
        parentName,
        name,
        phone,
        orderDate,
        orderPrice,
        comment,
        pickUpDate,
        mainPlanDone: mainPlanDone?mainPlanDone:'0',
        subPlanDone: subPlanDone?subPlanDone:'0',
        progress: JSON.parse(JSON.stringify(progress)),
        _id,
      };
    },
    // 确认添加
    onSubmit() {
      this.btnLoading = true;
      let param = { ...this.form };
      delete param._id;
      console.log(param, "新增");
      if (param.name.trim() == "" && param.phone.trim() == "") {
        this.$message({
          showClose: true,
          message: "请填写姓名或手机号",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      axios
        .post(this.$baseUrl + "/innerGrowUp/addConsumer", param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data, "添加成功");
            this.$message({
              duration: "2000",
              showClose: true,
              message: "添加成功！",
              type: "success",
            });
            this.dialogVisible = false;
            this.btnLoading = false;
            this.search();
          }
          if (res.data.code == 302) {
            this.$message({
              duration: "2000",
              showClose: true,
              message: "重复手机号请检查输入信息！",
              type: "warning",
            });
            this.btnLoading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    // 确认编辑
    onEdit() {
      this.btnLoading = true;
      let param = { ...this.form };
      console.log(param, "编辑");
      if (param.name.trim() == "" && param.phone.trim() == "") {
        this.$message({
          showClose: true,
          message: "请填写姓名或手机号",
          type: "warning",
        });
        this.btnLoading = false;
        return;
      }
      axios
        .post(this.$baseUrl + "/innerGrowUp/editInfo", param)
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data, "修改数据");
            this.$message({
              duration: "2000",
              showClose: true,
              message: "修改成功！",
              type: "success",
            });
            this.btnLoading = false;
            this.search();
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;
        });
    },
    // 添加流程
    addProgress() {
      this.form.progress.push({title: ''});
      this.$forceUpdate();
    },
    // 删除流程
    deleteProgress() {
      this.form.progress.pop();
      this.$forceUpdate();
    },
    // 修改每页选择数量
    changePageSize(val) {
      this.pages.pageNum = val;
      this.getData();
    },
  },
};
</script>
<style scoped lang="less">
.kmCenter {
  height: 100%;
  width: 100%;
  padding: 10px 50px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  .searchBar,
  .manlist {
    margin: 10px auto;
  }
  .searchBar {
    border: 1px solid #284faa;
    border-radius: 4px;
    padding-top: 22px;
  }
  .mainList {
    padding: 10px 0;
  }
  .redFont {
    font-weight: 600;
    color: #492525;
  }
  .blueFont {
    font-weight: 600;
    color: #2e62cd;
  }
  .greenFont {
    font-weight: 600;
    color: #349e69;
  }
}
</style>